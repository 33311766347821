import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'

const theme = {
  colors: {
    mastodon: {
      50: "#dffeec",
      100: "#b9f5d0",
      200: "#90edb3",
      300: "#65e495",
      400: "#3cdd78",
      500: "#6364FF",
      600: "#563ACC",
      700: "#2F0C7A",
      800: "#17063B",
      900: "#001803",
    },

    misskey: {
      50: '#f8ffdc',
      100: '#ebffaf',
      200: '#dfff7f',
      300: '#d2ff4d',
      400: '#c5ff1e',
      500: '#86b300',
      600: '#5f8000',
      700: '#384d00',
      800: '#384d00',
      900: '#111b00',
    }
  },
}

export default extendTheme(theme, withDefaultColorScheme({ colorScheme: 'orange' }))
